import React from 'react'
import { Dropdown as DropDownBs } from 'react-bootstrap';
import LoginAsUser from '../Users/LoginAsUser';
import ChangeAccountType from './ChangeAccountType';
import ChangeUserName from '../Users/ChangeUserName';
import DeleteUser from '../Users/DeleteUser';
import DisableUser from '../Users/DisableUser';
import ResetUserPassword from '../Users/ResetUserPassword';

const ActionsDropdown = ({ id, status, role }) => {
  const DROPDOWN_ACTIONS = [
    
    {
      name: "Login as User",
      component: <LoginAsUser id={id} status={status} />
    },
    {
      name: "Disable",
      component: <DisableUser id={id} status={status} />
    },
    {
      name: "Reset Password",
      component: <ResetUserPassword id={id} />
    },
    {
      name: "Change Account Type",
      component: <ChangeAccountType id={id} role={role} />
    },
    {
      name: "Change Username",
      component: <ChangeUserName id={id} />
    }
  ]

  return (
    <div>
      <DropDownBs drop="right" flip="true" className="Dropdown btnSizes transparentBtn px-0 py-0">
        <DropDownBs.Toggle id="dropdown-basic" className="transparentBtn">
          <span className="Dropdown_Label">Actions</span>
        </DropDownBs.Toggle>
        <DropDownBs.Menu className="Dropdown_Menu">
          {
            DROPDOWN_ACTIONS.map(item => {
              const { component, name } = item;

              return (
                <DropDownBs.Item as='button' tabIndex={0} key={name}> 
                  {component}
                </DropDownBs.Item>
              )
            })
          }
        </DropDownBs.Menu>
      </DropDownBs>
    </div>
  )
}

export default ActionsDropdown;