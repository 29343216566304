import React, { useContext } from 'react';
import classes from '../index.module.scss';
import { SearchContext } from '../../../context/Search';
import { ROLE_LIST } from '../../../constans';
import ActionsDropdown from '../../Users/ActionsDropdown';
import moment from 'moment';

const UsersTableBody = () => {
  const { searchedData } = useContext(SearchContext);

  return (
    <>
      {
        searchedData.map(item => {
          const { username, id, email, roles, is_active, registration_date } = item;
          const role = roles[0].role_description;
          const registration = moment(registration_date).format('MM/DD/YYYY');

          return (
            <tr 
              key={id}
            >
              <td className={`${classes.Title_Bold} ${classes.Vertical_Middle}`}>{username}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{email || "N/A"}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{registration}</td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>
                <span 
                  className={`${role} general`}
                >
                  {ROLE_LIST[role]}
                </span>
              </td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>
                <span 
                  className={`${is_active ? "active_status" : "not_responded"} general`}
                >
                  {is_active ? "Active" : "Disabled"}
                </span>
              </td>
              <td>
              <ActionsDropdown 
                id={id}
                status={is_active}
                role={role}
              />
              </td>
            </tr>
          )
        })
      }
    </>
  )
}

export default UsersTableBody;